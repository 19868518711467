import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { UiText } from '@uireact/text';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiList, UiListItem } from '../src/';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uilist"
    }}>{`UiList`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/UiList/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component used to render lists, bulleted, ordered or unordered`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/list`}</p>
    </blockquote>
    <h2 {...{
      "id": "uilist---default"
    }}>{`UiList - default`}</h2>
    <Playground __position={1} __code={'<UiList>\n  <UiListItem>\n    <UiText>Item 1</UiText>\n  </UiListItem>\n  <UiListItem>\n    <UiText>Item 2</UiText>\n  </UiListItem>\n</UiList>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      packageJson,
      Playground,
      UiList,
      UiListItem,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiList mdxType="UiList">
    <UiListItem mdxType="UiListItem">
      <UiText mdxType="UiText">Item 1</UiText>
    </UiListItem>
    <UiListItem mdxType="UiListItem">
      <UiText mdxType="UiText">Item 2</UiText>
    </UiListItem>
  </UiList>
    </Playground>
    <h2 {...{
      "id": "uilist---ordered"
    }}>{`UiList - ordered`}</h2>
    <Playground __position={2} __code={'<UiList type=\"ORDERED\">\n  <UiListItem>\n    <UiText>Item 1</UiText>\n  </UiListItem>\n  <UiListItem>\n    <UiText>Item 2</UiText>\n  </UiListItem>\n</UiList>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      packageJson,
      Playground,
      UiList,
      UiListItem,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiList type="ORDERED" mdxType="UiList">
    <UiListItem mdxType="UiListItem">
      <UiText mdxType="UiText">Item 1</UiText>
    </UiListItem>
    <UiListItem mdxType="UiListItem">
      <UiText mdxType="UiText">Item 2</UiText>
    </UiListItem>
  </UiList>
    </Playground>
    <h2 {...{
      "id": "uilist---bulleted"
    }}>{`UiList - bulleted`}</h2>
    <Playground __position={3} __code={'<UiList type=\"BULLETED\">\n  <UiListItem>\n    <UiText>Item 1</UiText>\n  </UiListItem>\n  <UiListItem>\n    <UiText>Item 2</UiText>\n  </UiListItem>\n</UiList>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      packageJson,
      Playground,
      UiList,
      UiListItem,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiList type="BULLETED" mdxType="UiList">
    <UiListItem mdxType="UiListItem">
      <UiText mdxType="UiText">Item 1</UiText>
    </UiListItem>
    <UiListItem mdxType="UiListItem">
      <UiText mdxType="UiText">Item 2</UiText>
    </UiListItem>
  </UiList>
    </Playground>
    <h2 {...{
      "id": "uilist---nested"
    }}>{`UiList - nested`}</h2>
    <Playground __position={4} __code={'<UiList type=\"BULLETED\">\n  <UiListItem>\n    <UiText>Item 1</UiText>\n    <UiList type=\"ORDERED\">\n      <UiListItem>\n        <UiText>Item 1.1</UiText>\n      </UiListItem>\n      <UiListItem>\n        <UiText>Item 1.2</UiText>\n      </UiListItem>\n    </UiList>\n  </UiListItem>\n  <UiListItem>\n    <UiText>Item 2</UiText>\n  </UiListItem>\n</UiList>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiText,
      packageJson,
      Playground,
      UiList,
      UiListItem,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiList type="BULLETED" mdxType="UiList">
    <UiListItem mdxType="UiListItem">
      <UiText mdxType="UiText">Item 1</UiText>
      <UiList type="ORDERED" mdxType="UiList">
        <UiListItem mdxType="UiListItem">
          <UiText mdxType="UiText">Item 1.1</UiText>
        </UiListItem>
        <UiListItem mdxType="UiListItem">
          <UiText mdxType="UiText">Item 1.2</UiText>
        </UiListItem>
      </UiList>
    </UiListItem>
    <UiListItem mdxType="UiListItem">
      <UiText mdxType="UiText">Item 2</UiText>
    </UiListItem>
  </UiList>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiList} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      